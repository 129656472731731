
import Vue from 'vue';

export default Vue.extend({
  name: 'Info',

  computed: {
    currentAward() {
      return this.$store.state.currentAward
    },
    didSignup () {
      return this.$store.state.didSignup
    },
    infoFromLogin () {
      return this.$store.state.infoFromLogin
    },
  },

  beforeDestroy() {
    this.$store.commit('setInfoFromLogin', false)
  },

});
